import "./assets/theme/index.css";
import "./assets/scss/index.scss";
import "./assets/scss/global.scss";
import "@/assets/styles/index.scss"; // global css
import "@/assets/styles/ruoyi.scss"; // ruoyi css
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import DictTag from "@/components/DictTag";
import ImagePreview from "@/components/ImagePreview";
// 字典数据组件
import DictData from "@/components/DictData";
import RightToolbar from "@/components/RightToolbar";

import { getToken,setToken } from "@/utils/auth";
import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  selectDictLabels,
} from "@/utils/ruoyi";
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import Pagination from "@/components/Pagination";
import { i18n,setI18nLanguage } from "./lang/index";
// 分页组件

Vue.component("DictTag", DictTag);
DictData.install();

router.beforeEach((to, from, next) => {
  if (getToken()) {
    store
      .dispatch("GetInfo", true)
      .then(() => {
        if (to.path === "/login") {
          next("/d/distributors");
        } else {
          next();
        }
      })
      .catch(() => {
        console.log('err')
        setToken(null)
        next("/login");
      });
  } else {
    next();
  }
});

Vue.component("RightToolbar", RightToolbar);
Vue.prototype.$image = (url) => {
  return process.env.VUE_APP_BASE_API + url;
};
Vue.component("ImagePreview", ImagePreview);
Vue.component("Pagination", Pagination);

Vue.prototype.getDicts = getDicts;
Vue.prototype.getConfigKey = getConfigKey;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectDictLabels = selectDictLabels;
Vue.prototype.setI18nLanguage = setI18nLanguage;

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

