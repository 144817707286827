import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
Vue.use(NProgress);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/d",
  },
  {
    path: "/d",
    component: () => import("@/views/Index"),
    children: [
      {
        path: "",
        redirect: "/d/distributors",
      },
      {
        path: "distributors",
        component: () => import("@/views/Distributors"),
      },
      {
        path: "stores",
        component: () => import("@/views/Stores"),
      },
      {
        path: "orders",
        component: () => import("@/views/Orders"),
      },
      {
        path: "reportDis",
        component: () => import("@/views/reportDis"),
      },
        {
            path: "reportStore",
            component: () => import("@/views/reportStore"),
        },
      {
        path: "reportAll",
        component: () => import("@/views/reportAll"),
      },
        {
        path: "orders",
        component: () => import("@/views/Orders"),
      },
      {
        path: "updateProfile",
        component: () => import("@/views/UpdateProfile"),
      },
      {
        path: "changePassowrd",
        component: () => import("@/views/ChangePassword"),
      },
    ],
  },

  {
    path: "/login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/register",
    component: () => import("@/views/Register"),
  },
  {
    path: "/resetPassword",
    component: () => import("@/views/ResetPassword"),
  },
  {
    path: "/forgotPassword",
    component: () => import("@/views/ForgotPassword"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
